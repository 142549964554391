.searchable-input {
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;
  width: 300px;
  position: relative;
  margin-top: 2%;
}

.label-name {
  text-align: left;
  font-family: PwC Helvetica Neue, sans-serif !important;
  font-size: 14px;
  letter-spacing: -0.4px;
  color: #203570;
  opacity: 1;
  margin-top: 5px;
  margin-bottom: 2px;
}

.input-name {
  text-align: left;
  font-family: Helvetica;
  letter-spacing: 0px;
  color: #202020;
  opacity: 1;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 2px;
  font-size: 14px;
  /*height: 30px;*/
  outline: none;
  width: 100%;
}
.input-action-search {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  top: 70px; /* Height of input + label */
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #f5f5f5;
  z-index: 30;
  border: 1px solid #f5f5f5;
  padding: 5px;
  position: absolute;
  max-height: 300px;
}
.input-action-item-style {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 5px;
  width: 100%;
  background-color: #f5f5f5;
}
.input-action-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 10px;
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-bottom: 10px;
  border-bottom: 2px solid black;
  background-color: #f5f5f5;
}
.input-action-search-results {
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  cursor: pointer;
}
.input-action-search-results::-webkit-scrollbar {
  width: 5px;
}

.input-action-search-results::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.input-action-search-results::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  background-color: #909090;
}
.input-action-search-results-item {
  width: 100%;
  cursor: pointer;
  color: black;
  font-size: 12px;
  margin-top: 5px;
}
.input-action-search-results-item:hover {
  background-color: #d8d8d8;
}

.css-1qo82xh-control {
  overflow: auto !important;
}
