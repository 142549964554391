body {
  margin: 0;
  font-family: PwC Helvetica Neue, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e8ebf0;
  /*max-zoom: 75%;*/
  /* background-image: url(); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root,
.App {
  height: 100%;
}

.css-1xc3v61-indicatorContainer {
  padding: 3px !important;
}

.css-1fdsijx-ValueContainer {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.bsRwzy {
  max-height: 482px !important;
}

.css-zj87q6-control {
  height: 25px !important;
}
.rdt_TableRow {
  width: 99% !important;
}
.rdt_TableHeadRow {
  min-height: 40px !important;
}
.ap-field-input {
  font-size: 0.8rem !important;
}
.ap-field .ap-field-title-container .ap-field-title {
  font-size: 0.8rem !important;
}
.ap-option-item {
  padding: 0.5rem !important;
  font-size: 0.8rem !important;
  height: 2.5rem !important;
}
