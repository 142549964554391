.header_bar{
    left:0px;
    top:0px;
    width:100%;
    height:7%;
    position:fixed;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 4px;
    opacity: 0.7;
}
.header_bar_left{
    position:absolute;
    display: flex;
    justify-content: left;
    align-items: center;
    left:1%;
    width:40%;
}
.header_bar_right {
    position: absolute;
    display: flex;
    justify-content: right;
    align-items: center;
    right: 1%;
}
.header_user_menu{
    height:30px;
    width: 30px;
    border-radius: 50%;
    color: white;
    font-weight: bold;
    font-size: 14px;
    background-color: #D85604;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:10px;
    cursor: pointer;
    border: 2px solid #D85604;
}
.header_user_menu:hover{
    color: #D85604;
    background-color: white;
}
.header_icon{
    /*width: 40px;*/
    height: 40px;
}
.header_separator{
    margin:10px;
    font-weight: bold;
}