@import "../node_modules/@appkit4/styles/themes/appkit.teal.min.css";
@import "@appkit4/react-components/dist/styles/appkit4-react.min.css";
.App {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* background: url(/src/assets/background/3.png) bottom left no-repeat,
    url(/src/assets/background/4.png) top left no-repeat; */
  background-size: contain;
  background-size: 60% 60%, cover;
}
.page_content {
  /*background-color: #E8EBF0;*/
  position: fixed;
  left: 6%;
  width: 92%;
  top: 7%;
  height: 85%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
.loader-dial {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.stop-overlay {
  position: relative;
  width: 110vw;
  height: 110vh;
  z-index: 100;
  background-color: #d0d0d0;
  opacity: 0.7;
}
