.deal_actions_outer {
  width: 100%;
  height: 89%;
  top: 11%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 15px;
}

.deal_actions_inner {
  width: 94%;
  left: 2%;
  height: 90%;
  top: 2%;
  position: absolute;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 50px;
}
.deal_detail_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 3%;
}
.deal_detail_container_pattern {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 150px;
  /* background: url("../assets/background/1.png"); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
  z-index: 30;
  opacity: 1;
  background-color: #d4e3e6;
  border-radius: 24px;
}
.deal_detail_container_design {
  /* margin-left: 25px;
    margin-top: 10px; */
}
.container-small {
  position: absolute;
  border-radius: 15px;
  top: 0%;
  height: 23%;
  width: 100%;
  background-color: #d4e3e6;
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;
  padding: 8px;
  /* background-image: url(/src/assets/background/1.png); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}
.container-mid {
  position: absolute;
  top: 25%;
  height: 75%;
  border-radius: 15px;
  width: 100%;
  z-index: 30;
  background-color: #d4e3e6;
  padding: 8px;
  /* background-image: url(/src/assets/background/1.png); */
  background-size: contain;
  background-repeat: no-repeat;
}
.container-large {
  position: absolute;
  height: 100%;
  border-radius: 15px;
  background-color: #d4e3e6;
  width: 100%;
  padding: 10px;
}
.card-header {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: #203570;
  margin-top: 2px;
  margin-bottom: 5px;
  display: contents;
}
.search-container {
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.css-18vfu35-control {
  width: 70% !important;
}

.confidential-container {
  color: #203570;
  float: right;
  font-size: 12px;
  display: inline;
}

.is-confindential {
  /*font: inherit;*/
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  /*border-radius: 0.15em;*/
  transform: translateY(0.2em);
}
