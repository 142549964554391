.menubar_simple{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:1%;
    width: 100%;
    height:9%;
    border-radius: 12px;
    background-color: #ffffff;    
}
.menubar_title{
    font-size: 24px;
    font-weight: bold;
    position: absolute;
    left:2%;    
}
.title_right{
    left:5%;      
}
.menubar_actions{
    display: flex;
    align-items: center;
    justify-content: right;
    position: absolute;  
    right:2%;
    width: 30%;   
}

.menubar_actions_create{
    display: flex;
    align-items: center;
    justify-content: center;  
    background-color:#289D8E;
    padding:5px;
    margin:5px;
    height:30px;
    cursor: pointer;
    border-radius:5px;
}
.menubar_action_left{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left:2%;
    cursor: pointer;
}
.user_table{
    width:100%;
    height:89%;
    position: absolute;
    top:12%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.user_actions_outer{
    width:100%;
    height:89%;
    position: absolute;
    top:12%;
    border-radius: 15px;
    background-color: #ffffff;
}
.user_actions_inner{
    width:96%;
    left:2%;
    height:90%;
    position: absolute;
    top:5%;
    border-radius: 15px;
    background-color: #f0f0f0;
    z-index: 10;
}
/*.userdata_form {
    position: absolute;
    display: grid;
    grid-template-columns: repeat(4,220px);
    grid-gap: 50px;
    width: 90%;
    left: 5%;
    margin: 10px;
}*/
/*.user_details_input{
    height:20px;
    width:200px;
    font-size: 14px;
    outline: none;
    box-shadow: none;
    border: none;
    padding:2px;
    margin-top:10px;
}*/
.username-button{
    outline: none;
    border: none;
    box-shadow: none;
    text-decoration: underline;
    background-color: transparent;
    color:#289D8E;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    margin: 4px;
}
.fAQsEo{
    /*font-size:16px!important;*/
}
.chJury{
    width:99%;
}

.kSDYzA{
    justify-content: center !important;
    margin-left: 3% !important;
}