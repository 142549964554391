.search-container {
  display: flex;
  align-items: center;
  margin: 10px;
  width: 330px;
  position: relative;
  height: 40px;
}
.searchbutton {
  position: absolute;
  margin: 0;
  background-color: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
  /*border: 2px solid #F0F0F0;*/
  padding: 4px;
  border: 2px solid #e9e9e9;
}
.iconpos-left {
  /* Position icon to right*/
  right: 0px;
}
.iconpos-right {
  /* Position icon to left*/
  left: 0px;
}
.searchbar {
  position: absolute;
  margin: 0;
  width: 0%;
  background-color: white;
  border-radius: 2px;
  border: 2px solid #f0f0f0;
  padding: 4px;
  border-radius: 2px;
  transition: width 0.4s ease-in-out;
  outline: none;
  height: 80%;
  font-size: 0.8rem;
}
.barpos-left {
  /* Position search bar to left - 1 px less than icon width*/
  right: 20px;
}
.barpos-right {
  /* Position search bar to right*/
  left: 30px;
}
.expand {
  width: 90%;
  transition: width 0.4s ease-in-out;
}
.searchbar::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
}
