.menubar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: absolute;
  left: 30%;
}

.menubar_stage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
}

.menubar_stage_item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 30%;
}

.menubar_stage_item_name {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 2px;
  padding: 2px;
}

.menubar_stage_item_name_title {
  font-size: 14px;
  margin: 4px;
  color: #203570;
}

.menubar_stage_round {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 2px;
}

.menubar_stage_item_topbar {
  width: 80%;
  height: 2px;
  border-radius: 3px;
}

.complete {
  background-color: #4cbb17;
  border: 1px solid #4cbb17;
}

.active {
  background-color: #203570;
  border: 1px solid #203570;
}

.inactive {
  background-color: #203570;
  border: 1px solid #203570;
  opacity: 0.4;
}

.round-complete {
  background-color: #4cbb17;
  border: 2px solid #4cbb17;
  color: #ffffff;
  font-size: smaller;
}

.round-active {
  border: 2px solid #203570;
  background-color: #203570;
  color: #ffffff;
  font-size: smaller;
}

.round-inactive {
  border: 2px solid #203570;
  background-color: #ffffff;
  color: #203570;
  font-size: smaller;
}
