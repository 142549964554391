.label-name {
    text-align: left;
    font-family: HelveticaBold;
    font-size: 16px;
    letter-spacing: -0.4px;
    color: #203570;
    opacity: 1;
}

.text-name {
    text-align: left;
    font-family: Helvetica;
    font-size: 14px;
    letter-spacing: 0px;
    color: #505050;
    opacity: 1;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    opacity: 1;
    padding: 5px 2px;
    height: 100px;
    width: 90%;
    outline: none;
    box-shadow: none;
}