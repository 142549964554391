.checklist{
    position: absolute;
    top:2%;
    width: 100%;
    height:88%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 5px;
}
.checklist_question{
    margin:10px;
    padding: 10px;
    width: 100%;
    height:100%;
}
.checklist_question_title{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
}
.checklist_question_description{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: bold;
    color: 	#787878;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    height: 30px;
}
.checklist_question_options{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;    
}
.checklist_options{
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;   
    top:95%;    
}
