.menubar_simple{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:0%;
    width: 100%;
    height:9%;
    border-radius: 12px;
    background-color: #ffffff;    
}
.menubar_title{
    font-size: 24px;
    font-weight: bold;
    position: absolute;
    left:2%;    
}
.title_right{
    left:5%;      
}
.menubar_actions{
    display: flex;
    align-items: center;
    justify-content: right;
    position: absolute;  
    right:2%;
    width:400px;   
}

.menubar_actions_create{
    display: flex;
    align-items: center;
    justify-content: center;  
    background-color:#289D8E;
    padding:5px;
    margin:5px;
    height:30px;
    cursor: pointer;
}
.menubar_action_left{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left:2%;
    cursor: pointer;
}
.user_table {
    width: 100%;
    height: 89%;
    position: absolute;
    top: 12%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    /*background-color: #E8EBF0;*/
}

.userdata_form{
    position: absolute;
    display: grid;
    grid-template-columns: repeat(4,200px);
    grid-gap:50px;
    width:90%;
    left:1%;
    margin:10px;
}
/*.user_details_input{
    height:30px;
    width:200px;
    font-size: 16px;
    outline: none;
    box-shadow: none;
    border: none;
    padding:5px;
    margin-top:10px;
}*/
/* .css-1xc3v61-indicatorContainer{
    padding-top:2px!important;
    padding-bottom:2px!important;
}

.css-qbdosj-Input {
    margin: 0px!important;
    padding-bottom: 0px!important;
    padding-top: 0px!important;
}*/
.username-button {
    outline: none;
    border: none;
    box-shadow: none;
    text-decoration: underline;
    background-color: transparent;
    color: #289D8E;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    margin: 4px;
}







.search-container {
  width: 280px;
  display: flex;
  flex-direction: column;
}


.cQTRuA::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.cQTRuA::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3); 
    border-radius: 10px;
}

.cQTRuA::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.5); 
    background-color: #909090;
}
.gtMGEr{
    justify-content:center!important;
}

.kSDYzA span.__rdt_custom_sort_icon__.asc i, .kSDYzA span.__rdt_custom_sort_icon__.asc svg{
    opacity:1;
}

.hyhXMF {
  
    min-width: 140px !important;
    max-width: 220px !important;
  
}

.hFyXs {
    min-width: 240px !important;
}

.diVuRk{
    min-width: 135px !important;
}

.dlitvY {
    min-width: 240px !important;
}
.UsOGQ{
    width: 99% !important;
}


.btn-disable {
    border: none;
    background: transparent;
    color: grey;
}

.css-3w2yfm-ValueContainer {
    height: 100px !important;
    overflow: auto !important;
}


.cYMhfa {
    justify-content: center !important;
}