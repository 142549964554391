.multi_select{
    max-width:96%;
    margin-left: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-height: 80%;
}
.multi_select_options {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 10px;
    display: none;
}
.multi_select_layout {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.multi_select_options_button{
    cursor:pointer;
    font-size:16px;
    margin: 5px;
    padding:5px;
    background-color:"#E8EBF0";
    color:"white";
}
.multi_select_options_button:disabled,
.multi_select_options_button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}