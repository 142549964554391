.login_content {
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center;
    align-items: center;
    flex-direction: column; */
}

.login_button {
  font-size: 18px;
}

.login_heading {
  font-size: 20px;
}
#PwC {
  max-height: 150px;
  max-width: 200px;
  width: 120px;
  height: 120px;
}
