.step_status_bar{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:90%;
    width: 100%;
    height:9%;
    border-radius: 5px;
    background-color: #ffffff;
}

.step_bullet{
    display: flex;
    border-radius: 50%;
    font-size: 14px;
    font-weight: bold;
    border: 3px solid #5f5f5f;
    padding:5px;
    margin:0px;
    width:22px;
    height: 22px;
    align-items: center;
    justify-content: center;
    color:black;
    background-color: #E8EBF0;
    cursor: pointer;
}
.step_bullet_active{
    display: flex;
    border-radius: 50%;
    font-size: 14px;
    font-weight: bold;
    border: 3px solid #279B8C;
    padding:5px;
    margin:0px;
    width:22px;
    height: 22px;
    align-items: center;
    justify-content: center;
    color:white;
    background-color: #289D8E;  
    cursor: pointer;  
}
.step_bullet .step_bullet_active:hover:after {
    content: attr(title);
    display: block;
    position: relative;
    top: -16px;
    right: -16px;
    width: 100px;
    background: lightblue;
}
.step_link{
    width:30px;
    margin:0px;
    height: 3px;
    background: #5f5f5f;
}
.step_link_active{
    width:30px;
    margin:0px;
    height: 3px;
    background: #279B8C;  
}
.step_status_bar_ui_button{
    font-size: 18px;
    border-radius: 2px;
    border: 1px solid #289D8E;
    padding:5px;
    background-color: #289D8E;
    cursor: pointer;
    border:2px solid #289D8E;
    color: white;
    outline:none;
}