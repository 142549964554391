.modal{
    position: relative;
    width: 100%;
    height:100%;  
}
.stop-overlay{
    position: fixed;
    left: 0;
    width: 100vw;
    height:100vh;
    z-index: 100;
    background-color: #D0D0D0;
    opacity: 0.7;
}
.modal-medium{
    position:fixed;
    top: 50%;
    left: 50%;
    width:800px;
    height:400px;
    margin-top: -200px; /*set to a negative number 1/2 of your height*/
    margin-left: -400px; /*set to a negative number 1/2 of your width*/
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: column;
    z-index: 150;
    opacity: 1;
    background-color: #D4E3E6;    
    background-image: url("../assets/background/1.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;
    /*
    background: url("../assets/background/1.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;

    */
}
/* .modal-heading{
    position: absolute;
    color: black;
    font-size: 36px;
    font-weight: bold;
    left:5%;
    margin:10px;
    padding: 10px;
}
.modal-content{
    position: absolute;
    color: black;
    font-size: 22px;
    margin:10px;
    padding: 10px;
    top:30%;
    left:5%;
    white-space: pre-wrap;
} */
.modal-actions{
    position: absolute;
    width: 90%;
    top:75%;
    height:25%;
    display: flex;
    align-items: center;
    justify-content: right;
    right:5%;
}
.user-form{
    width: 90%;
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    top:20%;
    left:5%;
}
/*.user_details_input{
    height:30px;
    width:300px;
    font-size: 18px;
    outline: none;
    box-shadow: none;
    border: none;
    padding:5px;
    margin-top:10px;
}*/

..css-1xc3v61-indicatorContainer{
    padding-top:0px!important;
    padding-bottom:0px!important;
}

.css-1xc3v61-indicatorContainer:hover {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}