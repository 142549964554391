.sidebar {
  background-color: #303030;
  position: fixed;
  left: 0px;
  width: 3.5%;
  top: 8%;
  height: 85%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  background-color: #203570;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.appname {
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  color: aliceblue;
}
.nav-item {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.nav-item-active {
  color: aliceblue;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 5px;
}
.nav-item:hover {
  background-color: #505050;
}

.nav-text {
  text-decoration: none;
  margin-left: 10px;
  font-size: 16px;
  display: inline-block;
  line-height: 50px;
}

.nav-icon {
  display: inline-block;
  line-height: 50px;
  margin: 5px;
  margin-top: 20px;
  padding: 5px;
  height: 32px;
  width: 32px;
}
.nav-icon-active {
  display: inline-block;
  line-height: 50px;
  margin: 5px;
  margin-top: 20px;
  padding: 5px;
  height: 32px;
  width: 32px;
  background-color: #289d8e;
  border-radius: 2px;
}

.nav-icon .nav-icon-active:hover:after {
  content: attr(title);
  display: block;
  position: relative;
  top: -16px;
  right: -16px;
  width: 100px;
  background: lightblue;
}
