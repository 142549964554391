.modal{
    position: relative;
    width: 100%;
    height:100%;  
}
.stop-overlay{
    position: fixed;
    width: 100vw;
    height:100vh;
    z-index: 100;
    background-color: #D0D0D0;
    opacity: 0.5;
}
.modal-small{
    position:fixed;
    top: 50%;
    left: 50%;

    width: 600px;
    height: 200px;
    margin-top: -85px;/*set to a negative number 1/2 of your height*/
    margin-left: -290px;/*set to a negative number 1/2 of your width*/

    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: column;
    background-color: #ffffff;
    z-index: 150;
}
.modal-heading{
    position: absolute;
    color: black;
    font-size: 22px;
    font-weight: bold;
    left:5%;
    margin:10px;
    padding: 10px;
}
.modal-content{
    position: absolute;
    color: black;
    font-size: 16px;
    margin:10px;
    padding: 10px;
    top:30%;
    left:5%;
    white-space: pre-wrap;
}
.modal-actions{
    position: absolute;
    width: 90%;
    top:75%;
    height:25%;
    display: flex;
    align-items: center;
    justify-content: right;
    right:5%;
}