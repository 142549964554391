/*.menubar_simple {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0%;
    width: 100%;
    height: 9%;
    border-radius: 12px;
    background-color: #ffffff;
}*/

.menubar_title {
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  left: 2%;
}

.title_right {
  left: 5%;
}

/*.menubar_actions {
    display: flex;
    align-items: center;
    justify-content: right;
    position: absolute;
    right: 2%;
    width: 400px;
}*/

.menubar_actions_create {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #289d8e;
  padding: 1px;
  margin: 5px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
}

.menubar_action_left {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 2%;
  cursor: pointer;
}

.user_table {
  width: 100%;
  height: 89%;
  position: absolute;
  top: 12%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.user_actions_outer {
  width: 100%;
  height: 89%;
  position: absolute;
  top: 12%;
  border-radius: 15px;
  background-color: #ffffff;
}

.user_actions_inner {
  width: 96%;
  left: 2%;
  height: 90%;
  position: absolute;
  top: 5%;
  border-radius: 15px;
  background-color: #d4e3e6;
  z-index: 10;
}

/*.userdata_form {
    position: absolute;
    display: grid;
    grid-template-columns: repeat(4, 220px);
    grid-gap: 15px;
    width: 90%;
    left: 1%;
    margin: 10px;
}
*/
.user_details_input {
  height: 20px;
  width: 200px;
  font-size: 14px;
  outline: none;
  box-shadow: none;
  border: none;
  border-radius: 4px;
  padding: 2px;
  margin-top: 10px;
}

/*. css-1xc3v61-indicatorContainer{
    padding-top:2px!important;
    padding-bottom:2px!important;
}*/

.username-button {
  outline: none;
  border: none;
  box-shadow: none;
  text-decoration: underline;
  background-color: transparent;
  color: #289d8e;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  margin: 4px;
}

/*hari code*/
/*.content-body {
    position: fixed;
    left: 6%;
    width: 92%;
    top: 20%;
    height: 70%;
    display: inline-flex;
     justify-content: flex-start;
    flex-direction: column; 
     align-items: center; 
}*/

.questions {
  top: 220px;
  left: 123px;
  width: 60%;
  background-color: #ffffff;
  border-radius: 16px;
  opacity: 1;
}

.overflow-y {
  /* background: url(/src/assets/background/2.png) bottom left no-repeat,
    url(/src/assets/background/1.png) top left no-repeat,
    linear-gradient(rgba(255, 255, 255, 1) 100%, rgba(0, 0, 0, 0.5) 100%);
  background-size: 60% 60%, cover !important; */
  background: #fff;
}

.questions-body,
.response-body {
  overflow-y: scroll;
  height: 90%;
}

.question-body-checklist {
  overflow-y: scroll;
  height: 89%;
}

.question-body-checklist::-webkit-scrollbar {
  width: 12px;
}

.question-body-checklist::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.question-body-checklist::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
/*Question body scroll bar*/
.questions-body::-webkit-scrollbar {
  width: 12px;
}

.questions-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.questions-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
/*Response body scroll bar*/
.response-body::-webkit-scrollbar {
  width: 12px;
}

.response-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.response-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.questions-title {
  /* Layout Properties */
  top: 221px;
  left: 123px;
  /*width: 1094px;*/
  padding: 10px 25px;
  background: #203570 0% 0% no-repeat padding-box;
  border-radius: 16px 16px 0px 0px;
  opacity: 1;
}

.response {
  margin-left: 40px;
  top: 220px;
  left: 123px;
  width: 38%;
  /*height: 600px;*/
  /*background: #FFFFFF 0% 0% no-repeat padding-box;*/
  border-radius: 16px;
  opacity: 1;
}

.response-title {
  /*width: 465px;*/
  padding: 10px 20px;
  /* UI Properties */
  background: #203570 0% 0% no-repeat padding-box;
  border-radius: 16px 16px 0px 0px;
  opacity: 1;
}

.title-name {
  padding-left: 32px;
  text-align: left;
  font: normal normal bold 18px/18px;
  font-family: Helvetica;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

/*.question-row {
    padding: 30px;
    background: 0% 0% no-repeat padding-box;*/
/* #D4E3E6 */
/*display: block;
    cursor: pointer;
}*/

.question-row {
  /*padding: 15px;*/
  margin-top: 2%;
  background: 0% 0% no-repeat padding-box;
  /* #D4E3E6 */
  display: inline-flex;
  cursor: pointer;
  width: 100%;
}

.question-numbers {
  padding: 5px 10px;
  width: 30px;
  height: 30px;
  background: #289d8e 0% 0% no-repeat padding-box;
  border: 2px solid #289d8e;
  border-radius: 6px;
  opacity: 1;
  color: white;
  text-align: center;
  margin: 0px 10px;
}
.question-numbers-checklist {
  padding: 5px 10px;
  padding-left: 10px;
  width: auto;
  height: 30px;
  background: #289d8e 0% 0% no-repeat padding-box;
  border: 2px solid #289d8e;
  border-radius: 6px;
  opacity: 1;
  color: white;
  text-align: center;
  margin: 0px 10px;
}

.question-text {
  /* Layout Properties */
  top: 300px;
  left: 232px;
  width: 500px;
  height: auto;
  /* UI Properties */
  /*text-align: left;*/
  /*font: normal normal normal 22px/18px;*/
  font-size: 14px;
  font-family: Helvetica;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.question-modified-date {
  float: right;
  margin-left: 2%;
}

.question-modified {
  /* Layout Properties */
  top: 292px;
  left: 1075px;
  width: 160px;
  height: 24px;
  /* UI Properties */
  text-align: left;
  /*font: normal normal normal 18px/24px;*/
  font-size: 12px;
  font-family: Helvetica;
  letter-spacing: 0px;
  color: #1b1b1b;
  opacity: 1;
}

.question-date {
  /* Layout Properties */
  top: 318px;
  left: 1075px;
  width: 245px;
  height: 24px;
  /* UI Properties */
  text-align: left;
  /*font: normal normal normal 18px/24px;*/
  font-size: 12px;
  font-family: Helvetica;
  letter-spacing: 0px;
  color: #939393;
  opacity: 1;
}

.response-row {
  padding: 15px 30px;
  background: 0% 0% no-repeat padding-box;
  display: block;
}

.opt-num {
  /* Layout Properties */
  top: 307px;
  left: 1346px;
  width: 59px;
  height: 21px;
  /* UI Properties */
  text-align: left;
  /*font: normal normal normal 16px/19px;*/
  font-size: 14px;
  font-family: Helvetica;
  letter-spacing: -0.4px;
  color: #203570;
  opacity: 1;
  padding: 20px;
}

.opt-text {
  /* Layout Properties */
  top: 304px;
  left: 1430px;
  width: 141px;
  height: 24px;
  /* UI Properties */
  text-align: left;
  /*font: normal normal normal 18px/24px;*/
  font-size: 12px;
  font-family: Helvetica;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.survey {
  border-radius: 6px;
  text-align: center;
  font: normal normal bold 24px/32px;
  font-weight: bold;
  font-family: Helvetica;
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;
}

.checkl {
  border-radius: 6px;
  text-align: center;
  font: normal normal normal 24px/32px;
  font-weight: bold;
  font-family: Helvetica;
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;
}

/* Custom select */

select {
  /* styling */
  background-color: white;
  border: thin solid #289d8e;
  color: #289d8e;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 4px 20px;
  border-radius: 8px;
  /* padding: 0.5em 3.5em 0.5em 1em; */
  /* reset */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.round {
  background-image: linear-gradient(45deg, transparent 50%, #289d8e 50%),
    linear-gradient(135deg, #289d8e 50%, transparent 50%),
    radial-gradient(#ffffff 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em - 2px),
    calc(100% - 15px) calc(1em - 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  border-color: #289d8e;
}

select.round:focus {
  background-image: linear-gradient(45deg, #289d8e 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, #289d8e 50%),
    radial-gradient(#ffffff 70%, transparent 72%);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

/* Custom file */

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.file-btn {
  border: 1px solid #289d8e;
  color: #289d8e;
  background-color: white;
  padding: 5px 30px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.no-record-display {
  text-align: center;
  margin: 20px;
  font-size: 20px;
  font-family: Helvetica;
}

/* khushal */

.tab-container {
  width: 92vw;
  /*height: 70vh;*/
  position: fixed;
  top: 18%;
  left: 6%;
}

.tab-data {
  height: 65vh;
  display: flex;
}

.content-body {
  position: fixed;
  left: 6%;
  width: 92%;
  top: 20%;
  display: flex;
  height: 75%;
}

/*Tab Styling*/
.Tab-list {
  display: inline-flex;
  left: 6%;
  width: 92vw;
  list-style-type: none;
  /*align-items: center;*/
  /*justify-content: center;*/
  /*overflow: hidden;*/
  padding-left: 0;
  /*margin-bottom: 2px;*/
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 20px;
}
.Tab-list::-webkit-scrollbar {
  width: 10px;
  display: none;
}

.Tab-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
}

.Tab-list::-webkit-scrollbar-thumb {
  border-radius: 12px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
}

.category-tab {
  display: flex;
  padding: 0px 40px;
  border-bottom: #7b7b7b 4px solid;
}

.category-tab > li {
  display: inline;
  padding: 3px;
}

.react-tabs__tab--selected {
  background: none !important;
  color: #289d8e !important;
  font-weight: 700 !important;
  border-bottom: #289d8e 4px solid !important;
}
/* custom select */
.select-box {
  position: relative;
  display: inline-block;
  min-height: 40px;
  max-height: 40px;
  width: 200px;
  margin-right: 20px;
  padding: 40px 0 0;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  overflow: hidden;
  box-shadow: 0 0 0 1px #9a9ca0;
  vertical-align: top;
  transition: 0.3s;
}

.select-box:after {
  content: "";
  position: absolute;
  width: 24px;
  height: 40px;
  top: 0;
  right: 10px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTI0IDI0SDBWMGgyNHoiIG9wYWNpdHk9Ii44NyIvPgogICAgICAgIDxwYXRoIGZpbGw9IiNBOUFDQUYiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTE1Ljg4IDE1LjI5TDEyIDExLjQxbC0zLjg4IDMuODhhLjk5Ni45OTYgMCAxIDEtMS40MS0xLjQxbDQuNTktNC41OWEuOTk2Ljk5NiAwIDAgMSAxLjQxIDBsNC41OSA0LjU5Yy4zOS4zOS4zOSAxLjAyIDAgMS40MS0uMzkuMzgtMS4wMy4zOS0xLjQyIDB6Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=);
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
  transform: rotate(180deg);
}

#hover-select:hover {
  max-height: 160px;
}

#hover-select:hover:after {
  transform: rotate(0deg);
}

.select-box > input {
  display: none;
}

.select-box > label {
  display: block;
  height: 40px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  line-height: 40px;
  cursor: pointer;
  transition: background-color 0.4s;
}

.select-box > label:hover {
  background-color: #f4f4f4;
}

.select-box > input:checked + label {
  position: absolute;
  top: 0;
}

.select-box > input:checked + label:hover {
  background-color: #fff;
  cursor: default;
}

#select-box-input {
  display: none;
}

#select-box-input:checked + label {
  max-height: 160px;
}

#select-box-input:checked + label:after {
  transform: rotate(0deg);
  background-position-x: right;
}

#select-box-input + label:after {
  width: 100%;
  right: 0;
  margin-right: 10px;
  background-position-x: left;
  background-position-y: center;
}

#select-box-input + label:hover {
  box-shadow: 0 0 0 1px #b28fff;
}

#select-box-input + label > label:hover {
  background-color: rgba(178, 143, 255, 0.2);
}

#select-box-input + label:hover:after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTI0IDI0SDBWMGgyNHoiIG9wYWNpdHk9Ii44NyIvPgogICAgICAgIDxwYXRoIGZpbGw9IiM1NTZBRUEiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTE1Ljg4IDE1LjI5TDEyIDExLjQxbC0zLjg4IDMuODhhLjk5Ni45OTYgMCAxIDEtMS40MS0xLjQxbDQuNTktNC41OWEuOTk2Ljk5NiAwIDAgMSAxLjQxIDBsNC41OSA0LjU5Yy4zOS4zOS4zOSAxLjAyIDAgMS40MS0uMzkuMzgtMS4wMy4zOS0xLjQyIDB6Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=);
}

#select-box-input + label:not(:focus) {
  max-height: 40px;
}

.response-form {
  display: inline-flex;
  flex-direction: row;
  /*padding-left:10px;*/
  padding-top: 20px;
  padding-bottom: 20px;
}

.response-form-feild {
  border: 2px solid #a0a0a0;
  border-radius: 4px;
  padding: 5px;
  margin-top: 4px;
}

.response-form-set {
  margin-left: 7%;
}

.overflow-y > hr {
  border-color: #a0a0a0;
}

.category-tab > span {
  margin-left: 5px;
}
