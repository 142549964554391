.summary_prompt{
    border: 1px solid #D85604;
    border-radius: 3px;
    font-size:14px;
    width: 60%;
    margin-left:5px;
    padding:5px;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-family: Arial, Helvetica, sans-serif;
}
.col_2_grid{
    display:flex; 
    flex-direction:row;
    align-items: center;
}

.summary_container{
    overflow-y: scroll; 
    height:600px;
}
.summary_container::-webkit-scrollbar {
    width: 10px;
}

.summary_container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}

.summary_container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

.summary_para{
    margin:5px;
    padding:5px;
    color:#303030;
    width:90%;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;
}